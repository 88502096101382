jQuery(document).ready(function($) {
    var page = 2; // We start from page 2 because the first page is already loaded
    $('#loadMoreBtnGuides').on('click', function() {
        $.ajax({
            url: ajax_vars.ajax_url, // The URL for the AJAX request
            type: 'POST',
            data: {
                action: 'load_more_guides', // Custom action name
                page: page // Send the current page to the server
            },
            beforeSend: function() {
                $('#loadMoreBtnGuides').text('Loading...'); // Change button text during loading
            },
            success: function(response) {
                if (response && response !== 'none') {
                    $('.guides-container').append(response); // Append the new posts
                    page++; // Increment the page count
                    $('#loadMoreBtnGuides').text('Load More'); // Reset button text
                } else {
                    $('#loadMoreBtnGuides').remove(); // Remove button if no more posts
                }
            }
        });
    });
});


jQuery(document).ready(function($) {

    let currentPage = 1;
    $('#loadMoreBtnCases').on('click', function() {
        currentPage++; // Do currentPage + 1, because we want to load the next page
        console.log("Clicked");

        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            dataType: 'html',
            data: {
                action: 'load_more_case_studies', // Action name
                paged: currentPage,
            },
            beforeSend: function() {
                $('#loadMoreBtnCases').text('Loading...'); // Change button text during loading
            },
            success: function(response) {
                console.log(response);
                if (response.html) {
                    $('.guides-container').append(response.html); // Append the new posts
                    page++; // Increment the page count
                    $('#loadMoreBtnCases').text('Load More'); // Reset button text

                    // If there are no more posts, remove the button
                    if (!response.has_more) {
                        $('#loadMoreBtnCases').remove(); // Remove button if no more posts
                    }
                } else {
                    $('#loadMoreBtnCases').remove(); // Remove button if no more posts
                }
            },
            error: function() {
                console.log("error");
                $('#loadMoreBtnCases').text('Load More'); // Reset button text if there's an error
            }
        });
    });
});

