document.addEventListener('DOMContentLoaded', function () {
    // The function to be executed when the section is in view
    function handleIntersection(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Perform your action here
                const pointItems = document.querySelectorAll(".map")
                pointItems.forEach((item, index) => {
                    setTimeout(() => {
                        item.classList.remove(("isShow"))
                    }, index * 400)
                })
                // You can unobserve if you only want to perform the action once
                observer.unobserve(entry.target);
            }
        });
    }

    // Create an IntersectionObserver instance
    const observer = new IntersectionObserver(handleIntersection, {
        root: null,  // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.1  // Adjust this value to control when the action is triggered
    });

    // Start observing the target element
    const target = document.getElementById('map-block');
    if (target) {
        observer.observe(target);
    }
});


jQuery(document).ready(function () {

    function toggleFooterNav() {
        if (jQuery(window).width() < 575) {
            jQuery('.footer-nav-parent').off('click').on('click', function (event) {
                event.preventDefault(); // Prevent the default action
                var footerList = jQuery(this).next('.footer-list');
                jQuery('.footer-list').not(footerList).slideUp(); // Slide up all other lists
                footerList.slideToggle(); // Toggle the clicked list

                jQuery('.footer-nav-parent').not(this).removeClass('active');
                jQuery(this).toggleClass('active');
            });
        } else {
            jQuery('.footer-nav-parent').off('click'); // Remove click events on larger screens
            jQuery('.footer-list').removeAttr('style'); // Ensure lists are reset
            jQuery('.footer-nav-parent').removeClass('active');
        }
    }

    // Run on load
    toggleFooterNav();

    // Run on window resize
    jQuery(window).resize(function () {
        toggleFooterNav();
    });

    // Block - Faq
    jQuery('.accordion-button').click(function() {
        var index = jQuery(this).data('index');
        var target = jQuery('#collapse' + index);

        if (target.hasClass('show')) {
            target.removeClass('show').slideUp(400);
            jQuery(this).removeClass('collapsed');
        } else {
            jQuery('.accordion-collapse.show').removeClass('show').slideUp(400);
            jQuery('.accordion-button').removeClass('collapsed');
            target.addClass('show').slideDown(400);
            jQuery(this).addClass('collapsed');
        }
    });

    // Block - Expand Functionality
    jQuery('.read-more-button').click(function(){ // Ensure this matches your button's class
        var expandedContent = jQuery('.expanded-content');
        var snippet = jQuery('.snippet-content');
        var button = jQuery(this);

        if(snippet.hasClass("hide-background")) {
            snippet.removeClass('hide-background');
        }else {
            setTimeout(() => {
                snippet.addClass('hide-background');
            }, 400)

        }
        // Toggle the slideToggle animation for expandedContent
        expandedContent.slideToggle(400, function() {
            // Determine the new state of the content
            var isExpandedVisible = expandedContent.is(':visible');


            // Update the button text based on the new state
            button.text(isExpandedVisible ? 'Read less' : 'Read more');
        });
    });


    var $stickyMenu = jQuery('.sticky-menu');
    if ($stickyMenu.length) {

        const $menuSelected = jQuery('.anchor-menu-selected');
        const $menuOptions = jQuery('.anchor-menu-options');
        const $arrow = jQuery('.custom-arrow');

        // Ensure menu options are hidden by default
        $menuOptions.hide();

        $menuSelected.on('click', function(event) {
            event.stopPropagation(); // Prevent the click from bubbling up
            $menuOptions.stop(true, true).slideToggle(300); // Toggle the dropdown
            $arrow.toggleClass('rotated'); // Toggle the rotation class
        });

        // Hide the dropdown when clicking outside of it
        jQuery(document).on('click', function(event) {
            if (!jQuery(event.target).closest('.anchor-menu').length) {
                $menuOptions.stop(true, true).slideUp(300);
                $arrow.removeClass('rotated'); // Ensure arrow points down when dropdown is closed
            }
        });

        // Prevent dropdown from closing when clicking inside it
        $menuOptions.on('click', function(event) {
            event.stopPropagation(); // Prevent the click inside the dropdown from closing it
        });

        // Smooth scroll when clicking on a link
        jQuery('.anchor-menu-options a').on('click', function(event) {
            event.preventDefault(); // Prevent the default anchor click behavior

            const target = jQuery(this.getAttribute('href')); // Get the target element

            if (target.length) {
                jQuery('html, body').stop(true, true).animate({
                    scrollTop: target.offset().top - 200
                }, 800); // Smooth scroll to the target (800ms duration)

                // jQuery('html, body').stop(true, true).animate({
                //     scrollTop: target.offset().top - 200
                // }, 800); // Smooth scroll to the target (800ms duration)

                $menuOptions.slideUp(300); // Close the dropdown after selection
                $arrow.removeClass('rotated'); // Rotate arrow back down
            }
        });

        var stickyOffset = $stickyMenu.offset()?.top;
        var divStickyMenuHeight = jQuery('.sticky-menu-height');
        var marginTop = parseInt($stickyMenu.css('margin-top'), 10) || 0;
        var marginBottom = parseInt($stickyMenu.css('margin-bottom'), 10) || 0;

        jQuery(window).on('scroll', function() {
            var scrollTop = jQuery(this).scrollTop();

            if (scrollTop >= stickyOffset) {
                divStickyMenuHeight.css('height', $stickyMenu.outerHeight() + marginTop + marginBottom + 'px');
                $stickyMenu.addClass('reached');
                $stickyMenu.css({
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    right: '0',
                    background: 'white',
                });
            } else {
                $stickyMenu.removeClass('reached');
                $stickyMenu.css({
                    position: 'sticky',
                    top: '0',
                    background: 'transparent'
                });
                divStickyMenuHeight.css('height', '0px');
            }
        });

        var $targetSection = jQuery('.anchor-block');
        var targetPosition = $targetSection.offset().top;

        let linkSections = [];
        let $linkstoSections = jQuery('.link-section');
        let selectElMobile = jQuery('.anchor-menu-select');

        $linkstoSections.each(function() {
            let sectionId = jQuery(this).attr('href').replace("#", "");
            let $sectionEl = jQuery(`#${sectionId}`);
            let sectionOffset = $sectionEl.offset().top;
            var elementHeight = $sectionEl.outerHeight();
            var offsetBottom = sectionOffset + elementHeight;
            let linkSectionData = {
                sectionId,
                linkEl: jQuery(this),
                sectionOffset,
                offsetBottom,
                sectionEl: $sectionEl,
            };
            linkSections.push(linkSectionData);
        });

        let paddingNum = 100;
        jQuery(window).width() < 992 ? paddingNum = 160 : paddingNum = 100;

        jQuery(window).on('scroll', function() {
            var currentScroll = jQuery(window).scrollTop();

            if (currentScroll >= targetPosition) {
                $targetSection.addClass("reached");
            } else {
                $targetSection.removeClass("reached");
            }

            linkSections.forEach(function(item) {
                if((currentScroll + $targetSection.outerHeight() + paddingNum) >= item.sectionOffset && (currentScroll + $targetSection.outerHeight() + paddingNum) <= item.offsetBottom) {
                    item.sectionEl.addClass("reached");
                    item.linkEl.addClass("active");
                    selectElMobile.find(`option[value=#${item.sectionId}]`).prop('selected', true)
                } else {
                    item.sectionEl.removeClass("reached");
                    item.linkEl.removeClass("active");
                }
            });
        });

        $linkstoSections.on('click', function(event) {
            event.preventDefault();
            let sectionId = jQuery(this).attr('href').replace("#", "");
            let $sectionEl = jQuery(`#${sectionId}`);
            let sectionOffset = $sectionEl.offset().top - paddingNum; // Adjust the scroll position to 80px from the top
            jQuery('html, body').animate({ scrollTop: sectionOffset }, 300);
        });

        selectElMobile.on('change', function() {
            let selectedVal = selectElMobile.find(":selected").attr('value');
            let sectionId = selectedVal.replace("#", "");
            let $sectionEl = jQuery(`#${sectionId}`);
            let sectionOffset = $sectionEl.offset().top - paddingNum; // Adjust the scroll position to 80px from the top
            jQuery('html, body').animate({ scrollTop: sectionOffset }, 300);

        });
    }

    // Initial check
    jQuery(window).trigger('scroll');


// Open modal when download button is clicked
    jQuery('.download-btn').on('click', function() {
        var postId = jQuery(this).data('post-id'); // Get post ID from data attribute
        var postTitle = jQuery(this).data('post-title'); // Get post title from data attribute

        jQuery('html').css('overflow','hidden');
        jQuery('#modalPostTitle').text(postTitle); // Assuming this is the span where the title should be displayed
        jQuery('#modalPostTitleSmall').text(postTitle); // Update the subtitle if needed

        jQuery('#downloadModal').fadeIn(); // Show the modal

        // Set the post ID in the hidden field
        jQuery('input[name="input_5"]').val(postId); // Assuming 'input_5' is your hidden field's ID

        // Prevent default action
        return false;
    });

    // Close modal when the close button is clicked
    jQuery('.close-button').on('click', function() {
        jQuery('#downloadModal').fadeOut();
        jQuery('html').css('overflow','visible');
    });

    // Close modal when clicking outside of the modal content
    jQuery(window).on('click', function(e) {
        if (jQuery(e.target).is('#downloadModal')) {
            jQuery('#downloadModal').fadeOut();
        }
    });

    jQuery(document).on('gform_confirmation_loaded', function(event, formId) {
        if (formId == 2) { // Replace with your form ID
            const thankYouModal = jQuery('.thank-you-modal-content');
            const modalForm = jQuery('.modal-content');

            modalForm.hide();
            thankYouModal.show();
            // Optionally reload or update a specific part of the page
            setTimeout(() => {
                location.reload();  // Or redirect the user to a specific page
            }, 1000)
        }
    });



    // Load More News

    const isNews = document.querySelector('.post-type-archive-news');
    const isBlog = document.querySelector('.post-type-archive-blogs');

    const loadingPosts = document.querySelector('.loading-posts');
    const loadMoreBtn = document.querySelector('#getMorePostsBtn');
    const clearFiltersBtn = document.querySelector('#clear-filters');
    const countryFilterOption = document.querySelectorAll('.country-option-item');
    const postsParentEl = document.querySelector('.posts-list');

    let activePage = 1;
    let perPageItems = 12;
    let isNextPage = true;
    let postsData = [];
    let filteredData = [];
    let paginatedData = [];
    const filterOptions = {
        country : '',
        tag : ''
    }
    let isMobile = false;
    let currentElements = [];


    function clearPostsFunction () {
        currentElements = [];
        if (postsParentEl) {  // Check if the element exists
            postsParentEl.innerHTML = '';
        }
        filterOptions.country = '';
        filterOptions.tag = '';
        activePage = 1;
        isNextPage = true;
    }

    async function getPostsData () {

        // showLoader();
        clearPostsFunction();
        fetch('/wp-json/custom/v1/news-posts/')
            .then(response => response.json())
            .then(data => {
                postsData = data;
                filterWithLocalPostsData();
                closeLoader();
            })
            .then(() => {
                window.addEventListener('resize', function () {
                    if (window.innerWidth < 767) {
                        if (!isMobile) {
                            postsParentEl.innerHTML = '';
                            renderPosts(currentElements);
                            // getPostsData();
                        }
                        isMobile = true;
                    } else {
                        if (isMobile) {
                            postsParentEl.innerHTML = '';
                            renderPosts(currentElements);
                            // getPostsData();
                        }
                        isMobile = false;
                    }


                })
            })
            .catch(error => console.error('Error fetching news posts:', error));
    }

    async function getBlogsData () {

        // showLoader();
        clearPostsFunction();
        fetch('/wp-json/custom/v1/blogs-posts/')
            .then(response => response.json())
            .then(data => {
                postsData = data;
                filterWithLocalPostsData();
                closeLoader();
            })
            .then(() => {
                window.addEventListener('resize', function () {
                    if (window.innerWidth < 767) {
                        if (!isMobile) {
                            postsParentEl.innerHTML = '';
                            renderPosts(currentElements);
                            // getPostsData();
                        }
                        isMobile = true;
                    } else {
                        if (isMobile) {
                            postsParentEl.innerHTML = '';
                            renderPosts(currentElements);
                            // getPostsData();
                        }
                        isMobile = false;
                    }


                })
            })
            .catch(error => console.error('Error fetching blog posts:', error));
    }

    function renderPosts(array) {

        if (array.length === 0) {
            // If no posts are available, display the message
            const noPostsEl = document.createElement('div');
            noPostsEl.classList.add('no-posts-message');
            noPostsEl.innerHTML = `
            <p>No news found with these filters, please click clear or choose different items in filter to see agility news.</p>
        `;
            postsParentEl.append(noPostsEl);
        } else {
            // If there are posts, render them as usual
            array.forEach((item) => {
                const postEl = document.createElement('div');
                postEl.classList.add('col-lg-4', 'col-md-6');

                if (window.innerWidth < 767) {
                    postEl.innerHTML = `
                <div class="post-item-image"><img src="${item.thumbnail}"></div>
                <div class="post-item-data-hover mt-4">
                    <h4 class="title">${item.title}</h4>
                    <div class="description">${item.description}</div>
                    <a class="post-item" href="${item.link}">
                        <div class="button mt-3"><button class="btn btn--white-dark-blue">Read More</button></div>
                    </a>
                </div>
            `;
                } else {
                    postEl.innerHTML = `
                <a class="post-item" href="${item.link}">
                    <div class="post-item-image"><img src="${item.thumbnail}"></div>
                    <div class="post-item-data">
                        <h4 class="title">${item.title}</h4>
                    </div>
                    <div class="post-item-data-hover">
                         <div class="description-hover">
                            <h4 class="title">${item.title}</h4>
                            <div class="description">${item.description}</div>
                        </div>
                        <div class="button"><button class="btn btn--white-dark-blue">Read More</button></div>
                    </div>
                </a>
            `;
                }

                postsParentEl.append(postEl);
            });
        }
    }

    function paginate(array) {
        // Calculate the starting index of the items for the current page
        const startIndex = (activePage - 1) * perPageItems;

        // Slice the array to get the items for the current page
        const pageItems = array.slice(startIndex, startIndex + perPageItems);

        // Determine if there are more items for a next page
        const totalItems = array.length;
        isNextPage = activePage * perPageItems < totalItems;
        activePage++;

        // Return the items for the current page and the updated nextPage and activePage
        return pageItems;
    }

    function filterData(array, filterObject) {

       let filteredCountryArray = array.filter((postItem) => {
           let foundCountryItem = true;

           if (filterObject.country !== '') {
               foundCountryItem = postItem.categories.find(item => {
                   return item.slug === filterObject.country
               })
           }
            return !!foundCountryItem
        })

        let filteredTypeArray = filteredCountryArray.filter(postItem => {
        // for array Value
            let foundTypeItem = true;

            if (filterObject.tag !== '') {
                foundTypeItem = postItem.tags.find(item => {
                    return item.slug === filterObject.tag
                })
            }
            return !!foundTypeItem

        // for string Value
        // return postItem.type === filterObject.type
        })

        return filteredTypeArray;
    }

    function loadMorePosts () {
        triggerLoaderTimeOut();
        paginatedData = paginate(filteredData)
        currentElements.push(...paginatedData);
        renderPosts(paginatedData);

        if (!isNextPage) {
            loadMoreBtn.classList.add('d-none');
        } else {
            loadMoreBtn.classList.remove('d-none');
        }


    }

    function filterWithLocalPostsData () {
        filteredData = filterData(postsData, filterOptions);
        currentElements = [];
        paginatedData = paginate(filteredData)
        currentElements.push(...paginatedData);

        renderPosts(paginatedData);

        if (!isNextPage) {
            loadMoreBtn.classList.add('d-none');
        } else {
            loadMoreBtn.classList.remove('d-none');
        }


    }

    function showLoader() {
        if (loadingPosts) {
            loadingPosts.classList.remove('d-none');
        }
    }

    function closeLoader () {
        loadingPosts.classList.add('d-none');
    }

    function triggerLoaderTimeOut () {
        showLoader();
        setTimeout(() => {
            closeLoader();
            //filterWithLocalPostsData();
        }, 1500)
    }

    if(isNews) {
        getPostsData();
    }

    if(isBlog) {
        getBlogsData();
    }

    if (loadMoreBtn) {
        loadMoreBtn.addEventListener('click', loadMorePosts);
    }
    if (clearFiltersBtn) {
        clearFiltersBtn.addEventListener('click', async () => {
            const clearInitValues = [{
                key: 'country',
                value: 'Select Country'
            },
                {
                    key: 'industry',
                    value: 'Select a Type'
                }];

            triggerLoaderTimeOut();
            returnValue(clearInitValues, '.filter-desktop-version .anchor-menu-category');
            returnValue(clearInitValues, '.filter-mobile-version .anchor-menu-category');
            clearPostsFunction();
            filterWithLocalPostsData();
        });
    }

    function returnValue (dataValues, parentClass) {
        jQuery(parentClass).each(function () {
            jQuery(this).find('.country-option-item').each(function () {
                jQuery(this).removeClass('active');
            })
        })

        dataValues.forEach( item => {
            const selectionCateogry  = jQuery(`${parentClass}[data-value=${item.key}]`)

            const selectedEl = selectionCateogry.find('.anchor-menu-selected');
            selectedEl.html('');
            selectedEl.append(item.value)
            selectedEl.append('<div class="custom-arrow"><img src="https://agilityeor.com/wp-content/uploads/2024/07/arrow.png" alt="arrow"></div>');
        })
    }

/*    countryFilterOption.forEach(item => {
        item.addEventListener('click', (target) => {
            triggerLoaderTimeOut();
            clearPostsFunction();
            const filterOptionVal = target.target.getAttribute('data-value');

            filterOptions.country = filterOptionVal;

            console.log('Test - 1')

            filterWithLocalPostsData();
        })
    })*/


    // Hide the country-options initially for each menu category
    jQuery('.anchor-menu-category .country-options').hide();

    // Toggle country-options when the arrow is clicked
    jQuery('.anchor-menu-category .anchor-menu-selected').on('click', function() {

        // Toggle the country-options within the current anchor-menu-category
        jQuery(this).siblings('.country-options').slideToggle();
    });

    // Event delegation to handle country selection
    jQuery('.anchor-menu-category').on('click', '.country-option-item', function() {


        triggerLoaderTimeOut();

        currentElements = [];
        postsParentEl.innerHTML = '';
        activePage = 1;
        isNextPage = true;

        if ((jQuery(this).parent().attr('id')) === 'tag-options') {
            const filterOptionVal = jQuery(this).attr('data-value');
            filterOptions.tag = filterOptionVal;
        } else {
            const filterOptionVal = jQuery(this).attr('data-value');
            filterOptions.country = filterOptionVal;
        }

        filterWithLocalPostsData();

        var selectedCountry = jQuery(this).text();
        var menuCategory = jQuery(this).closest('.anchor-menu-category');
        // var previousCountry = menuCategory.find('.anchor-menu-selected').text().trim();

        // Update the selected country text
        menuCategory.find('.anchor-menu-selected').text(selectedCountry);

        // Replace the selected country with the previously selected country in the list
        // jQuery(this).text(previousCountry);

        // Hide the country-options after selection within the current anchor-menu-category
        menuCategory.find('.country-options').slideUp();
        menuCategory.find('.country-option-item').each(function () {
            jQuery(this).removeClass('active');
        })
        // jQuery('.anchor-menu-category .country-option-item').each(function () {
        //     jQuery(this).removeClass('active');
        // })

        jQuery(this).addClass('active');

        // Re-add the arrow icon after updating the text
        menuCategory.find('.anchor-menu-selected').append('<div class="custom-arrow"><img src="https://agilityeor.com/wp-content/uploads/2024/07/arrow.png" alt="arrow"></div>');
    });

    function initializeMegaMenuEvents() {
        const $megaMenu = jQuery('.nav-link.mega-menu');
        const $globalCoverageMenu = jQuery('.global-coverage-menu');
        const $wrapperNavbar = jQuery('#wrapper-navbar');
        const defaultLogo = jQuery('.default-logo');
        const megaMenuLogo = jQuery('.mega-menu-logo');
        let isHovering = false;

        // Function to disable body scroll and enable scroll on the global coverage menu
        function toggleBodyScroll(disable) {
            const innerWidth = window.innerWidth - jQuery(document).width();

            if (disable) {
                jQuery('html').addClass('no-scroll').css('padding-right', `${innerWidth}px`);
                $globalCoverageMenu.css({
                    'overflow-y': 'auto',
                    'max-height': '100%'
                });
            } else {
                jQuery('html').removeClass('no-scroll').css('padding-right', '0px');
                jQuery('body').css('overflow', '');
                $globalCoverageMenu.css({
                    'overflow': '',
                    'max-height': ''
                });
            }
        }

        // Check if the mega menu exists and then bind hover events, only if screen width > 767px
        if (window.innerWidth > 767 && $megaMenu.length > 0) {
            // Add event for hovering over the mega menu link
            $megaMenu.on('mouseenter', function() {
                if (isBurgerMenuActive) return; // Exit if burger menu is active
                $megaMenu.addClass('open');
                $globalCoverageMenu.stop(true, true).fadeIn(300).addClass('show');
                toggleBodyScroll(true); // Disable body scrolling and enable menu scrolling
            });

            // Add event for when leaving the mega menu link
            $megaMenu.on('mouseleave', function() {
                if (isBurgerMenuActive) return; // Exit if burger menu is active
                setTimeout(checkMousePosition, 400);
            });

            // Add event for hovering over the global coverage menu
            $globalCoverageMenu.on('mouseenter', function() {
                if (isBurgerMenuActive) return; // Exit if burger menu is active
                isHovering = true; // Set flag to prevent closing
            });

            // Add event for leaving the global coverage menu
            $globalCoverageMenu.on('mouseleave', function() {
                if (isBurgerMenuActive) return; // Exit if burger menu is active
                isHovering = false;
                setTimeout(checkMousePosition, 100); // Small delay to ensure smooth transition
            });

            // Function to check if mouse is on either the mega-menu or the global coverage menu
            function checkMousePosition() {
                if (isBurgerMenuActive) return; // Exit if burger menu is active
                if (!isHovering) {
                    $globalCoverageMenu.stop(true, true).fadeOut(100).removeClass('show');
                    $megaMenu.removeClass('open');
                    toggleBodyScroll(false); // Restore body scrolling when menu is hidden
                }
            }
        } else {
            console.error('Mega menu link not found.');
        }
    }


    // Fetch menu items and dynamically populate the Global Coverage menu
    fetch('/wp-json/custom/v1/menu/')
        .then(response => response.json())
        .then(menuItems => {
            // Find the parent item with the title 'Global Coverage'
            const globalCoverageItem = menuItems.find(item => item.title === 'Global Coverage');
            const $globalCoverageLink = jQuery('a[title="Global Coverage"]');

            // Check if the Global Coverage link exists and add the mega-menu class
            if ($globalCoverageLink.length > 0) {
                $globalCoverageLink.addClass('mega-menu');
            } else {
                console.error('Global Coverage link not found.');
            }

            if (globalCoverageItem && globalCoverageItem.children && globalCoverageItem.children.length > 0) {
                // Target the row inside the Global Coverage menu container
                const rowContainer = document.querySelector('.global-coverage-menu .row .col-lg-9.col-md-12 .row');

                globalCoverageItem.children.forEach(child => {
                    // Create a new HTML element for each child menu item (Level 2)
                    const colDiv = document.createElement('div');
                    colDiv.classList.add('col-lg-4', 'col-md-4');  // Add column class

                    // Create the child item (Level 2) with its title and link
                    let childHTML = `
                    <a class="reg-link" href="${child.url}">
                        <h4>${child.title}</h4>
                    </a>`;

                    // Check if the child item has sub-children (Level 3)
                    if (child.children && child.children.length > 0) {
                        // Add a container for sub-child items (Level 3)
                        childHTML += '<ul class="sub-menu">';
                        child.children.forEach(subChild => {
                            // Add each sub-child item as a list item
                            childHTML += `
                            <li>
                                <a href="${subChild.url}">
                                    ${subChild.title}
                                </a>
                            </li>`;
                        });
                        childHTML += '</ul>';
                    }

                    // Insert the constructed child and sub-child HTML into the column div
                    colDiv.innerHTML = childHTML;

                    // Append the child (and its sub-children) to the row container
                    rowContainer.appendChild(colDiv);
                });
            } else {
                console.error('Global Coverage menu item or its children not found.');
            }

            // Add a button to view all countries at the bottom of the global coverage menu
            const rowContainer = document.querySelector('.global-coverage-menu .row .col-lg-9.col-md-12 .row');
            const btnDiv = document.createElement('div');
            btnDiv.classList.add('col-lg-12', 'col-md-12');
            const btnViewAllCountries = document.querySelector('.btn-mega-menu');
            btnDiv.appendChild(btnViewAllCountries);
            rowContainer.appendChild(btnDiv);

            // Initialize mega menu events after fetching menu items
            initializeMegaMenuEvents();
        })
        .catch(error => {
            console.error('Error fetching menu items:', error);
        });

    jQuery(window).resize(function() {
        initializeMegaMenuEvents();
    });



    function toggleBodyScroll(disable) {
        const innerWidth = window.innerWidth - jQuery(document).width();
        const $globalCoverageMenu = jQuery('.global-coverage-menu');


        if (disable) {
            jQuery('html').addClass('no-scroll').css('padding-right', `${innerWidth}px`); // To add no-scroll class
            $globalCoverageMenu.css({
                'overflow-y': 'auto', // Enable scrolling for the global coverage menu
                'max-height': '100%', // Restrict its height to the viewport
            });
        } else {
            jQuery('html').removeClass('no-scroll').css('padding-right', '0px');
            jQuery('body').css('overflow', ''); // Restore body scrolling
            $globalCoverageMenu.css({
                'overflow': '', // Reset overflow
                'max-height': '' // Reset max-height
            });
        }
    }


    // Mobile Menu

    // Global flag to track burger menu state
    let isBurgerMenuActive = false;

    jQuery('.burger').on('click', function() {
        toggleBodyScroll(true);

        // Toggle the 'active' class for both the burger and the mobile menu
        const megaMenuLogo = jQuery('.mega-menu-logo');
        const burger = jQuery('.burger');
        const closeBurger = jQuery('.close-burger');
        const wrapperNavBar = jQuery('#wrapper-navbar');
        const globalCoverageMenuMobile = jQuery('.global-coverage-menu.mobile');
        const html = jQuery('html');

        jQuery(this).toggleClass('active');
        jQuery('.global-coverage-menu.mobile').toggleClass('active');

        const logoMobileDark = jQuery('.default-logo');
        const logoMobileWhite = jQuery('.mega-menu-logo');

        if (jQuery('.global-coverage-menu.mobile').hasClass('active')) {
            // Burger menu is now active
            isBurgerMenuActive = true;
            html.css('overflow','hidden');
            wrapperNavBar.css('background', '#1C2638');
            logoMobileDark.css('display', 'none');
            logoMobileWhite.css('display', 'block');
            burger.css('display', 'none');
            closeBurger.css('display', 'block');
            globalCoverageMenuMobile.css('display', 'block');
        } else {
            // Burger menu is now inactive
            isBurgerMenuActive = false;
            html.css('overflow','visible');
            logoMobileDark.css('display', 'block');
            logoMobileWhite.css('display', 'none');
            burger.css('display', 'block');
            closeBurger.css('display', 'none');
            wrapperNavBar.css('background', 'white');
            globalCoverageMenuMobile.css('display', 'none');
        }
    });


    // Handle the close-burger click to reverse the changes
    jQuery('.close-burger').on('click', function() {
        toggleBodyScroll(false);
        const megaMenuLogo = jQuery('.mega-menu-logo');
        const burger = jQuery('.burger');
        const closeBurger = jQuery('.close-burger');
        const wrapperNavBar = jQuery('#wrapper-navbar');
        const html = jQuery('html');



        jQuery('.global-coverage-menu.mobile').removeClass('active');
        jQuery('.burger').removeClass('active');

        const logoMobileDark = jQuery('.default-logo');
        const logoMobileWhite = jQuery('.mega-menu-logo');

        // Reset to the default state
        html.css('overflow','visible');
        logoMobileDark.css('display', 'block');
        logoMobileWhite.css('display', 'none');
        burger.css('display', 'block');
        closeBurger.css('display', 'none');
        wrapperNavBar.css('background', 'white');
    });






    jQuery('.menu-item').on('mouseenter', function() {
        const $menuItem = jQuery(this);
        const $navLink = $menuItem.find('.nav-link'); // Get the nav-link inside the menu-item
        const $dropdownMenu = $menuItem.find('.dropdown-menu'); // Get the dropdown-menu inside the menu-item
        const $globalCoverageMenu = jQuery('.global-coverage-menu');
        const $megaMenuNavLink = jQuery('.nav-link.mega-menu'); // Target the mega-menu nav-link

        // Check if the menu-item has a dropdown-menu and neither has the 'mega-menu' class
        if ($dropdownMenu.length > 0 && !$menuItem.hasClass('mega-menu') && !$navLink.hasClass('mega-menu')) {
            // Add the 'show' class to the menu-item
            $menuItem.addClass('show');

            // Add the 'show' class to the dropdown menu
            $dropdownMenu.addClass('show');

            // Hide the global coverage menu and remove 'open' class from mega-menu link if the current menu item has the 'show' class
            if ($menuItem.hasClass('show')) {
                $globalCoverageMenu.hide(); // Immediately hide the global coverage menu
                $megaMenuNavLink.removeClass('open'); // Remove 'open' class from mega-menu link
            }

            // Add event listener for when mouse enters the dropdown-menu
            $dropdownMenu.on('mouseenter', function() {
                $dropdownMenu.addClass('show'); // Keep the 'show' class
            });

            // Add event listener for when mouse leaves the dropdown-menu
            $dropdownMenu.on('mouseleave', function() {
                $dropdownMenu.removeClass('show'); // Remove the 'show' class when mouse leaves
            });
        }
    });


// Add event listener for mouseleave on the menu-item
    jQuery('.menu-item').on('mouseleave', function() {
        const $menuItem = jQuery(this);
        const $navLink = $menuItem.find('.nav-link'); // Get the nav-link inside the menu-item
        const $dropdownMenu = $menuItem.find('.dropdown-menu'); // Get the dropdown-menu inside the menu-item

        // Check if the menu-item has a dropdown-menu and neither has the 'mega-menu' class
        if ($dropdownMenu.length > 0 && !$menuItem.hasClass('mega-menu') && !$navLink.hasClass('mega-menu')) {
            // Remove the 'show' class from the menu-item and dropdown menu when mouse leaves
            setTimeout(function() {
                if (!$dropdownMenu.is(':hover') && !$menuItem.is(':hover')) {
                    $menuItem.removeClass('show'); // Remove 'show' class from menu-item
                    $dropdownMenu.removeClass('show'); // Remove 'show' class from dropdown menu
                }
            }, 200); // Delay to ensure smooth transition
        }
    });



// Variable to track clicks on the <a> element
    var clickCount = 0;

// Handle clicks on the <a> tag
    jQuery('.menu-item-has-children > a').click(function(e) {
        e.preventDefault(); // Prevent immediate redirect

        var $menuItem = jQuery(this).parent(); // Get the parent <li> element
        var $subMenu = $menuItem.find('.sub-menu').first(); // Find the sub-menu within this menu item

        clickCount++;

        if (clickCount === 1) {
            // First click: Show the sub-menu
            $subMenu.slideDown();
            $menuItem.addClass('sub-menu-active'); // Add a class for active state (for rotating icon)
        } else if (clickCount === 2) {
            // Second click: Redirect to the link
            window.location.href = jQuery(this).attr('href');
        }
    });

// Handle clicks on the dropdown icon
    jQuery('.dropdown-icon').click(function(e) {
        e.stopPropagation(); // Prevent event from propagating to <a> tag
        var $menuItem = jQuery(this).parent(); // Get the parent <li> element
        var $subMenu = $menuItem.find('.sub-menu').first(); // Find the sub-menu within this menu item

        if ($subMenu.is(':visible')) {
            // If sub-menu is visible, hide it and reset
            $subMenu.slideUp();
            $menuItem.removeClass('sub-menu-active');
            clickCount = 0; // Reset the click counter
        } else {
            // If sub-menu is hidden, show it
            $subMenu.slideDown();
            $menuItem.addClass('sub-menu-active');
            clickCount = 0; // Reset the click counter so the next click on <a> can redirect
        }
    });

    // Get the height of the entire page content
    // const pageContentHeight = document.body.scrollHeight;
    //
    // // Set the threshold for height (1700px in this case)
    // const heightThreshold = 1700;
    //
    // // Get the footer-shapes-top element
    // const footerShapesTop = document.querySelector('.footer-shapes-top');
    //
    // // Get the hero shape container (ensure this is the correct selector for the hero shape)
    // const heroShapeContainer = document.querySelector('.hero-shape');
    //
    // // Get the full country list page
    // const fullCountryPage = document.querySelector('.page-id-993');
    //
    // // Get the full country list page
    // const catBCountryPage = document.querySelector('.country-cat-b');
    //
    // // Check if the page has the error404 class
    // const isError404 = document.body.classList.contains('error404');
    //
    // // Check if the page content height is less than the threshold for the footer-shapes-top or error404 class exists
    // if (footerShapesTop) {
    //     if (pageContentHeight < heightThreshold && !isError404) {
    //         // Hide the footer-shapes-top if the height is less than 1700px and error404 class does not exist
    //         footerShapesTop.style.display = 'none';
    //     } else {
    //         // Show the footer-shapes-top if the height is greater than 1700px or error404 class exists
    //         footerShapesTop.style.display = 'block';
    //     }
    // }
    //
    // // Check if the page content height is greater than the threshold for the hero shape
    // if (heroShapeContainer) {
    //     if (pageContentHeight > heightThreshold && !fullCountryPage || catBCountryPage) {
    //         // Apply the curve if the height exceeds 1700px
    //         heroShapeContainer.classList.add('show-curve');
    //     } else {
    //         // Remove the curve if the height is below the threshold
    //         heroShapeContainer.classList.remove('show-curve');
    //     }
    // }


    var navElement = jQuery('.nav-element');
    navElement.hover(
        function() {
            var href = jQuery(this).find('a').attr('href');

            if (href) {
                jQuery(this).on('click', function() {
                    window.location.href = href;
                });
            }
        }
    );


    function setVh() {
        // Calculate viewport height and convert it to a CSS custom property
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // Initial calculation
    setVh();

    // Recalculate on window resize and orientation change
    window.addEventListener('resize', setVh);
    window.addEventListener('orientationchange', setVh);
});

