document.addEventListener('DOMContentLoaded', function() {
    var swiper = new Swiper('.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        initialSlide: 1,
        breakpoints: {
            575: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 4.5,
                spaceBetween: 15,
            },
        },
    });


    var swiperRelevancy = new Swiper('.container-relevancy', {
        slidesPerView: 1.2,
        spaceBetween: 10,
        centeredSlides: true,
        autoHeight: false,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        initialSlide: 1,
        breakpoints: {
            575: {
                slidesPerView: 2.5,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 2.5,
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 4.5,
                spaceBetween: 15,
                centeredSlides: false,
            },
        },
    });

    var sliderLogo = new Swiper('.logos-container', {
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        autoHeight: false,
        loop: true,
        loopFillGroupWithBlank: true, // Adds blank slides if needed
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        //initialSlide: 1,
        breakpoints: {
            575: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 4, // or any other value that works for your design
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 5, // Ensure it's less than the number of slides
                spaceBetween: 0,
                centeredSlides: false, // Try disabling centeredSlides if needed
            },
        },
    });



// Select all Swiper containers
    const swiperContainers = document.querySelectorAll('.swiper-container-global-archieve');

    let value;
    let screenWidth = window.innerWidth;

    if (screenWidth < 575) {
        value = 1.2;
    } else if (screenWidth < 768) {
        value = 1.8;
    } else if (screenWidth < 991) {
        value = 2.5;
    } else if (screenWidth < 1500) {
        value = 3.2;
    } else {
        value = 4.5;
    }

    swiperContainers.forEach(container => {
        const swiperWrapper = container.querySelector('.swiper-wrapper');
        const slides = swiperWrapper ? swiperWrapper.children : [];
        const minimumSlides = Math.ceil(value * 2);

        if (slides.length > 3) {
            // Initialize Swiper with loop and standard settings
            new Swiper(container, {
                spaceBetween: 10,
                centeredSlides: true,
                loop: true,
                allowTouchMove: true,
                speed: 300,
                autoplay: { delay: 3000 },
                initialSlide: 1,
                breakpoints: {
                    320: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    575: {
                        slidesPerView: 1.8,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 30,
                    },
                    992: {
                        slidesPerView: 3.2,
                        spaceBetween: 30,
                    },
                    1500: {
                        slidesPerView: 4.5,
                        spaceBetween: 30,
                    },
                },
            });
        } else if (slides.length === 3 && screenWidth < 1199) {
            // Special one-way scroll setup for exactly 3 slides on screens < 1199px
            new Swiper(container, {
                spaceBetween: 10,
                centeredSlides: true,
                loop: false, // Disable looping for one-way effect
                allowTouchMove: true,
                speed: 300,
                autoplay: false, // Disable autoplay
                slidesPerView: 1,
                slidesPerGroup: 1, // Scroll one slide at a time
                initialSlide: 1, // Start from the second slide
                breakpoints: {
                    320: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    575: {
                        slidesPerView: 1.8,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 30,
                    },
                    992: {
                        slidesPerView: 3.2,
                        spaceBetween: 30,
                    },
                },
                on: {
                    reachEnd: (swiper) => {
                        swiper.allowTouchMove = true; // Stop scrolling at the last slide
                    },
                },
            });
        } else if (slides.length === 2 && screenWidth < 575) {
            // Special one-way scroll setup for exactly 2 slides on screens < 575px
            new Swiper(container, {
                spaceBetween: 10,
                centeredSlides: true,
                loop: false, // Disable looping for one-way effect
                allowTouchMove: true,
                speed: 300,
                autoplay: false, // Disable autoplay
                slidesPerView: 1,
                slidesPerGroup: 1, // Scroll one slide at a time
                initialSlide: 0, // Start from the first slide
                breakpoints: {
                    320: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                },
                on: {
                    reachEnd: (swiper) => {
                        swiper.allowTouchMove = true; // Stop scrolling at the last slide
                    },
                },
            });
        } else {
            // If 3 or fewer slides, add static-swiper class to prevent any Swiper actions
            container.classList.add('static-swiper');
        }
    })
});